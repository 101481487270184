const ApiConstants = {
  // User
  GET_USER_TIP: "user/tip/",
  GET_FEATURED_USERS: "featured-users/",
  GET_STATS: "user-stats/",

  // Dashboard
  GET_PROFILE: "profile/",
  GET_CALLS_DATA: "bookingsv2/",
  GET_PACKAGE_CALLS_DATA: "package-booking/",
  GET_PRODUCT_BOOKING_DATA: "document-services/",
  GET_REFERRAL: "referral/",
  GET_TESTIMONIAL: "testimonials/",
  GET_SAMPLE_SERVICES: "sample-services/",
  GET_SERVICES_LIST_LITE: "services-lite/",
  CHECK_USERNAME: "check-username/?username=",
  GET_USER_REWARDS: "user-rewards/",
  GET_EXPERTISE: "expertise/",
  GET_ANALYTICS: "user-booking-analytics-beta/",
  GET_DASHBAORD_STATS: "user-stats-v2/",
  GET_NUDGE: "nudge-details/",
  GET_STEPS: "onboarding-steps/",
  UPDATE_ONBOARDING_ACTIONS: "onboarding-action/",
  GET_SHARE_TEMPLATES: "sharing-content-list/",
  GET_SHARE_TEMPLATES_V2: "sharing-content-list-v2/",
  GET_BOOKING_DETAILS: "booking-detail-info/",
  GET_VIDEO_KEYS: "videocall_keys/",
  GET_SCHEDULES: "schedules/",
  GET_SLOTLIST: "slots_list/",
  GET_BLOCKED_DATES: "blocked-dates/",
  GET_BLOCKED_DATE: "blocked-date/",
  GET_SCHEDULE: "schedule/",
  GET_ACCOUNTING_DETAILS: "accounting-details/",
  GET_CAN_WITHDRAW_MONEY: "can-withdraw-money/",
  GET_YEAR_RECAP: "year-end-wrap/",
  REPORT_ISSUE: "report-issue/",
  GET_FREE_LINK: "create-free-call-link/",
  GET_CALENDAR_LIST: "calendar-auth/",
  GET_SHARING_META: "sharing-content-meta/",
  GET_WEBINAR_REGISTRATIONS: "bookingsv2-group-call/",
  GET_DOC_REGISTRATIONS: "document-sales-list/",
  GET_REGISTRATIONS_LIST: "bookingsv2-group-call-download/",
  GET_DOC_BUYERS_DOWNLOAD: "document-sales-download/",
  GET_PRODUCT_TESTIMONIALS: "document-sales-reviews/",
  GET_SERVICE_DETAILS: "service/",
  GET_SERVICES_ORDER: "services-order/",
  GET_USER_REFERRALS: "referral/",
  GET_DASHBOARD_SERVICES: "services/",
  GET_AI_POSTS: "api/integrations/post-examples/",
  GET_SSO_Token: "canny/token/",
  GET_BOOKING_INFO: "booking-detail/",
  GET_QUERY_INFO: "user-query-status/",
  GET_DOC_INFO: "buy-document-detail/",
  TRANSACTION_DETAILS: "transaction-details/",
  SERVICE_DOC_UPLOADS: "service-link/",
  TOP_EARNERS: "top-earners",
  TESTIMONIAL_REORDER: "testimonials-reorder/",
  PLEDGE_DONATION: "donation-pledge/",
  RESCHEDULE_BOOKING_COMPLETED: "reschedule-booking-completed/",
  GET_DONORS: "donation-leaderboard/",
  GET_USER_STORIES: "all-story-post/",
  GET_CROSS_SELL_SERVICES: "cross-sell-services/",
  THANKSGIVING_MESSAGES: "thanks-giving-message/",
  THANKSGIVING_MESSAGES_UPDATE: "thanks-giving-message-update/",
  GET_WEBINAR_TESTIMONIALS: "webinar-testimonials/",
  SERVICE_SHOWCASE_TESTIMONIALS: "service-testimonial-showcase/",
  SEND_STORY: "story-post/",
  GET_JOIN_CALL_LINK: "join-call-link/",
  DELETE_ACCOUNT: "profile/delete/",
  GET_ANALYTICS_DATA: "embed-analytics-url/",
  GET_DASHBOARD_BANNERS: "dashboard-banner/",

  // Marketing
  GET_SAMPLE_EMAIL_CAMPAIGNS: "marketing/sample-campaign/",
  CAMPAIGNS: "marketing/campaign/",
  MARKETING_FUNNELS: "marketing/user-funnels/",
  MARKETING_FUNNEL_DETAILS: "marketing/user-funnel/",
  GET_SAMPLE_FUNNELS: "marketing/funnel-configs/",
  CONTACTS_LIST: "marketing/contact-list/",
  FUNNEL_SERVICE_LISTS: "service-for-funnel/",
  SEND_TEST_CAMPAIGN: "marketing/test-campaign/",
  CREDIT_TOPUP: "marketing/credit-topup/",
  BUY_CREDITS: "marketing/buy-credits/",
  BOOKING_NOTES: "booking-meeting-note/",

  // Share
  GET_SHARING_TEMPLATES_V2: "sharing-sections/",
  GET_SHARING_CATEGORY: "sharing-posts/?section=",
  GET_SHARING_TEMPLATE_DETAILS: "sharing-post/",
  SHARE_ON_LINKEDIN: "share-post/",
  GET_AI_POST_PARAMS: "generate-post-parameters/",
  GENERATE_AI_POST: "generate-post/",

  DISCOUNT_CODE: "coupon/",
  VALIDATE_CODE: "coupon/validate/",
  POST_TESTIMONIAL: "share-on-linkedin/",
  ADD_SAMPLE_SERVICES: "add-sample-services/",
  RESCHEDULE_BOOKING: "reschedule-booking/",
  CLAIM_REWARD: "claim-reward/",
  UPDATE_PASSWORD: "update-password/",
  SOCIAL_AUTH: "api/social-auth/",
  SEND_EMAIL: "bookingsv2-group-call-email/",
  SEND_PACKAGE_EMAIL: "package-booking-email/",
  SEND_PRODUCT_EMAIL: "email-document-consumers/",
  UPDATE_FROM_LINKEDIN: "update-profile-from-linkedin/",
  ASK_TESTIMONIAL: "ask-for-testimonial/",
  ASK_TESTIMONIAL_WEBINAR: "ask-for-testimonial-webinar/",
  CREATE_CALL_EXPERIENCE: "create-call-experience/",
  CANCEL_WEBINAR: "cancel-webinar/",

  ADD_SLOTS: "slots/",

  UPDATE_PROFILE: "profile/",
  UPDATE_MILESTONE: "make-nudge-seen/",

  // Public
  GET_BOOKING_DATA: "booking-detail/",
  GET_PACKAGE_BOOKING_DATA: "package-booking-detail/",
  GET_RESCHEDULE_SERVICES: "reschedule-booking-service/",
  GET_SERVICES: "service-public/",
  GET_BY_USERNAME: "fetchByUsername/",
  GET_BY_USERNAMELITE: "fetchByUsernameLite/",
  GET_BY_USERNAME_ADDITIONAL: "fetchByUsernameAdditionalDetails/",
  GET_AVAILABLE_SLOTS: "availability/",
  APPLY_COUPON: "apply-coupon/",
  GET_POST_ANALYTICS: "linkedin-posts/",
  GET_LINKEDIN_PROFILE: "create-user-from-linkedin/",
  GET_SIMILAR_PROFILES: "demo-user-by-expertise/?expertise_id=",
  TOP_FOLLOWERS: "top-followers-opt-in/",
  GET_USE_CASE_CATEGORIES: "user-categorys/",
  GET_COUNTRY: "geo/country/",
  FOLLOWER_REQUEST_RESCHEDULE: "reschedule-booking-request/",
  GET_ORG: "org-config/",
  GET_AVAILABLE_USERS: "org-availability/",
  GET_TESTIMONIALS: "public-testimonials/",
  REVALIDATE_CACHE: "api/revalidate",
  REVALIDATE_PAYGLOCAL: "api/payglocalpay",
  PAYMENTS_PAYGLOCAL: "payments/payglocal/callback/",
  GET_FOR_YOU: "sharing-recommendation/",
  GET_EXPERT_FROM_QUERY: "semantic-search/?query=",
  RATE_SEARCH_EXPERT: "search-feedback/",
  SUPPORT_MY_WORK: "create-support-my-work/",
  AI_WAIT_LIST: "join/search/",
  RESCHEDULE_CALL_FOLLOWER: "reschedule-booking-follower/",
  DOWNLOAD_CHRISTMAS_MESSAGES: "download-messages/",
  START_CHAT_SESSION: "chat-conversation/",
  GET_PREVIOUS_CHAT: "chat-messages",
  GET_CHAT_QUERY_RESPONSE: "chat-response/",

  // Public Post/Patch
  CANCEL_MEETING_FOLLOWER: "cancel-booking-follower/",
  RESCHEDULE_BOOKING_SLOTS: "reschedule-booking-slots/",
  CREATE_BOOKING: "create-booking/",
  CREATE_PACKAGE_BOOKING: "create-package-booking/",
  REQUEST_A_SLOT: "request-slot/",
  GET_EXPERT_CATEGORIES: "discover-experts/",
  GET_PACKAGE_WEBINAR: "package-services/sustainability_101/",
  SUBSCRIBE: "subscribe/",
  POST_BILLBOARD: "billboard/",
  GET_BOOKING_STATUS: "booking-status-details/",
  GET_SMART_DISCOUNT_CODE: "/smart-discount/",
  CREATE_LEAD: "follower/capture-lead/",
  UPDATE_BOOKING: "update-booking/",

  //Stripe account
  BANK_ACCOUNT_STATUS: "bank-account-status/",
  BANK_ACCOUNT_DETAILS: "bank-account-details/",
  CREATE_BANK_ACCOUNT: "create-bank-account-details/",
  ADD_STRIPE_ACCOUNT: "stripe-account/",

  //Custom page
  CUSTOM_USER_DATA: "org-users/",
  AI_POST_CONTENT: "sharing-content-prompt/",
  SHARING_DATA: "share-data/",
  DYNAMIC_PRICE: "sample-ppp/",

  // Beamer
  GET_BEAMER_NPS_STATUS: "nps/check",

  // AI Image Api's
  PUT_TERMS: "api/v1/core/terms/",
  GET_AI_IMAGES: "api/v1/core/assets/?username=",
  REFRESH_AVATAR: "api/v1/core/assets/",
};

export default ApiConstants;
