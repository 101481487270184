import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  applyCoupon,
  getAnalytics,
  getAvailableSlots,
  getBankAccountDetails,
  getBookingDetails,
  getCustomUserData,
  getDashboardStats,
  getForYouTemplates,
  getNextSteps,
  getProfile,
  getPublicServices,
  getRescheduleBookingSlots,
  getRescheduleServices,
  getSampleServices,
  getShareTemplates,
  getSharingInfo,
  getTopEarners,
  getTransactionDetails,
  getUserByUsername,
  getUserByUsernameAdditional,
  getUserStats,
  updateProfile,
  updateService,
} from "services/dashboard-services";
import {
  apiSocialAuth,
  getOrg,
  getUseCaseCategories,
} from "services/public-services";
import config from "utils/config";

export const APIStateKeysEnum = {
  Profile: "profile",
  Stats: "user_stats",
  UserByUserName: "userByUserName",
  UserByUserNameAdditional: "userByUserNameAdditional",
  UserByUserNameLite: "userByUserNameLite",
  PublicService: "service_public",
  Coupon: "coupon",
  ShareTemplates: "shareTemplates",
  ShareTemplatesV2: "shareTemplatesV2",
  ShareData: "shareData",
  UserStats: "userStats",
  UserDashboardStats: "userDashboardStats",
  CheckSteps: "checkSteps",
  AccountingDetails: "paymentsData",
  WithdrawMoney: "withdrawMoney",
  UseCaseCategories: "useCaseCategories",
  BankAccountStatus: "bankAccountStatus",
  BankAccountDetails: "bank_details",
  ServiceDetails: "serviceDetails",
  Schedules: "schedules",
  Country: "country",
  SampleServices: "sampleServices",
  Referral: "referral",
  DashboardService: "service_dashboard",
  AIPosts: "ai_posts",
  Org: "org",
  OrgUsers: "orgUsers",
  ShareCategories: "share_categories",
  TransactionDetails: "transactionDetails",
  BookingDetails: "booking_details",
  ExpertCategories: "expert_categories",
  PublicTestimonials: "public_testimonials",
  TopEarners: "top_earners",
  ForYouTemplates: "for_you_templates",
  BillboardData: "billboard",
  AvatarList: "avatar_list",
  ThanksGivingMessages: "thanksgiving",
  SmartDiscount: "smart_discount",
};

export const useProfile = (enabled = true): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.Profile],
    queryFn: getProfile,
    staleTime: 300000,
    enabled: enabled ? true : false,
  });

  return {
    user: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useOrg = (): any => {
  function changeFavicon(src) {
    var link = document.createElement("link"),
      oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.Org],
    queryFn: getOrg,
    staleTime: 600000,
    enabled: config?.IS_WHITELABEL === "true" ? true : false,
    onSuccess: (res) => {
      changeFavicon(res?.listing_config?.favicon || res?.logo);
    },
  });

  return {
    org: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useStats = (): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.Stats],
    queryFn: getUserStats,
    staleTime: 300000,
  });

  return {
    stats: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useBankAccountDetails = (): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.BankAccountDetails],
    queryFn: getBankAccountDetails,
    staleTime: 300000,
  });

  return {
    bank_details: data,
    error: error,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useGetUserSlots = (
  service_id,
  user_id,
  interval,
  bookingID
): any => {
  const { data, error, isLoading, isSuccess, isStale, status, isRefetching } =
    useQuery({
      queryKey: ["slots", service_id, user_id, interval, bookingID],
      queryFn: (): any =>
        bookingID
          ? getRescheduleBookingSlots(bookingID)
          : getAvailableSlots(service_id, user_id, interval),
      staleTime: 180000,
    });

  return {
    slots: data?.data,
    error: error,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
    isRefetching: isRefetching,
  };
};

export const useGetServicePublic = (service_id, bookingID): any => {
  const { data, error, isLoading, isSuccess, isStale, status, isRefetching } =
    useQuery({
      queryKey: ["service_public", service_id, bookingID ? bookingID : null],
      queryFn: (): any =>
        bookingID
          ? getRescheduleServices(bookingID)
          : getPublicServices(service_id),
      staleTime: 180000,
      enabled: service_id ? true : false,
    });

  return {
    service: data,
    error: error,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
    isRefetching: isRefetching,
  };
};

export const useGetUserByUsername = (username): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.UserByUserName, username],
    queryFn: (): any => getUserByUsername(username),
    staleTime: 360000,
    enabled: username ? true : false,
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    user: data,
    error: error,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useGetUserByUsernameAdditional = (username, variant): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.UserByUserNameAdditional, username, variant],
    queryFn: (): any => getUserByUsernameAdditional(username, variant),
    staleTime: 360000,
    enabled: username && ["A", "B", "a", "b"]?.includes(variant) ? true : false,
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    user: data,
    error: error,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useGetShareTemplates = (): any => {
  const {
    data,
    error,
    isLoading,
    isSuccess,
    isStale,
    status,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [APIStateKeysEnum.ShareTemplates],
    queryFn: async () => await getShareTemplates(),
    staleTime: 180000,
  });

  return {
    templates: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
    isRefetching: isRefetching,
    refetch: refetch,
  };
};

export const useGetShareData = (): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.ShareData],
    queryFn: async () => await getSharingInfo(),
    staleTime: 180000,
  });

  return {
    shareData: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useBookingDetails = (booking_id): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.BookingDetails],
    queryFn: async () => await getBookingDetails(booking_id),
    staleTime: 180000,
    enabled: booking_id ? true : false,
  });

  return {
    bookingData: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useNextStepsData = (): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.CheckSteps],
    queryFn: async () => await getNextSteps(),
    staleTime: 30000,
  });

  return {
    checkSteps: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useUserStats = (time): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.UserStats, time],
    queryFn: async () => await getAnalytics(time),
    staleTime: 180000,
  });

  return {
    userStats: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useUserDashboardStats = (time): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.UserDashboardStats, time],
    queryFn: async () => await getDashboardStats(time),
    staleTime: 180000,
  });

  return {
    userDashboardStats: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useServiceTemplates = (type): any => {
  const { data, error, isLoading, isSuccess, isStale, status } = useQuery({
    queryKey: [APIStateKeysEnum.SampleServices],
    queryFn: async () => await getSampleServices(type),
    staleTime: 180000,
  });

  return {
    sampleService: data,
    isLoading: isLoading,
    isError: error,
    isSuccess: isSuccess,
    isStale: isStale,
    status: status,
  };
};

export const useCategoriesUseCase = (id): any => {
  const { data, error, isLoading, status } = useQuery({
    queryKey: [APIStateKeysEnum.UseCaseCategories, id],
    queryFn: async () => await getUseCaseCategories(id),
    staleTime: 180000,
  });

  return {
    categories: id !== 0 ? data : data,
    isLoading: isLoading,
    isError: error,
    status: status,
  };
};

export const useUpdateProfile = (): any => {
  return useMutation((obj) => updateProfile(obj));
};

export const useUpdateService = (): any => {
  return useMutation((obj) => updateService(obj));
};

export const useUpdateCoupon = (service_id): any => {
  const queryClient = useQueryClient();
  return useMutation((obj) => applyCoupon(obj), {
    onSuccess: () => {
      queryClient.invalidateQueries([APIStateKeysEnum.Coupon, service_id]);
    },
  });
};

export const useSocialSignin = (): any => {
  return useMutation((obj) => apiSocialAuth(obj));
};

export const useTransactionData = (
  type: any = "all",
  service: any = null,
  filter: any = "",
  page: any = 1
): any => {
  const { data, error, isLoading, status } = useQuery({
    queryKey: [
      APIStateKeysEnum.TransactionDetails,
      [type, service, filter, page],
    ],
    queryFn: async () =>
      await getTransactionDetails(type, service, filter, page),
    staleTime: 180000,
    keepPreviousData: true,
  });

  return {
    transactionData: data,
    isLoading: isLoading,
    isError: error,
    status: status,
  };
};

export const useCustomOrgUser = (): any => {
  const { data, error, isLoading, status } = useQuery({
    queryKey: [APIStateKeysEnum.OrgUsers],
    queryFn: async () => await getCustomUserData(),
    staleTime: 600000,
    enabled: config?.IS_WHITELABEL === "true" ? true : false,
  });

  return {
    orgUsers: data,
    isLoading: isLoading,
    isError: error,
    status: status,
  };
};

export const useTopEarners = (type): any => {
  const { data, error, isLoading, status } = useQuery({
    queryKey: [APIStateKeysEnum.TopEarners, type],
    queryFn: async () => await getTopEarners(type),
    staleTime: 180000,
    keepPreviousData: true,
  });

  return {
    topEarners: data,
    isLoading: isLoading,
    isError: error,
    status: status,
  };
};

export const useForYouSharing = (): any => {
  const { data, error, isLoading, status } = useQuery({
    queryKey: [APIStateKeysEnum.ForYouTemplates],
    queryFn: async () => await getForYouTemplates(),
    staleTime: 180000,
    keepPreviousData: true,
  });

  return {
    forYouTemplates: data,
    isLoading: isLoading,
    isError: error,
    status: status,
  };
};
