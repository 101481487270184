import { insertSpaces } from "utils/Analytics/Analytics";

export const Routes = [
  {
    name: "PublicHomepage",
    pattern: "/",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Logout",
    pattern: "/logout",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Login",
    pattern: "/sign-in",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "SignUp",
    pattern: "/signup",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "ForgotPassword",
    pattern: "/forgot-password",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Terms",
    pattern: "/terms",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Privacy",
    pattern: "/privacy",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "About",
    pattern: "/about",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Contact",
    pattern: "/contact",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Pricing",
    pattern: "/pricing",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "storypage",
    pattern: "/stories",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "storypage Chapter1",
    pattern: "/stories/chapter1",
    type: "public",
    redirect: "/stories",
    mobileNav: false,
  },
  {
    name: "storypage Chapter2",
    pattern: "/stories/chapter2",
    type: "public",
    redirect: "/stories",
    mobileNav: false,
  },
  {
    name: "PublicProfile",
    pattern: "/[username]",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Referral",
    pattern: "/join/[username]",
    type: "public",
    redirect: false,
    mobileNav: true,
    commonPath: "/join",
  },
  {
    name: "TimesSquare",
    pattern: "/times-square",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "TimesSquareSupport",
    pattern: "/celebrating-you",
    type: "public",
    redirect: false,
    mobileNav: true,
  },

  {
    name: "ServicePage",
    pattern: "/[username]/[service]",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ServicePaymentPage",
    pattern: "/[username]/[service]/pay",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "CarouselMaker",
    pattern: `/carousel`,
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Logout",
    pattern: "/logout",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "AutoLogin",
    pattern: "/auto-login",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "AuthorizeLinkedinShare",
    pattern: "/authorise",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ConsumerAuthorizeLinkedinShare",
    pattern: "/authorise-consumer",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "SocialSignIn",
    pattern: "/auth/:type",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "QuerySuccess",
    pattern: "/query-success/[queryId]",
    type: "public",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "OnboardingAbout",
    pattern: "/onboarding/about-you",
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "OnboardingTags",
    pattern: "/onboarding/tag",
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "OnboardingServices",
    pattern: "/onboarding/services",
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "OnboardingAvailability",
    pattern: "/onboarding/availability",
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "OnboardingWhatsappUpdates",
    pattern: "/onboarding/whatsapp-updates",
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "DashboardHome",
    pattern: `/dashboard/home`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ShareOnLinkedin",
    pattern: `/dashboard/share-post`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "CreateAPost",
    pattern: `/dashboard/create-post/[templateID]`,
    type: "private",
    redirect: false,
    mobileNav: false,
    commonPath: "/create-post",
  },
  {
    name: "AIWriterPost",
    pattern: `/dashboard/ai-writer`,
    type: "private",
    redirect: false,
    mobileNav: false,
    commonPath: "/ai-writer",
  },
  {
    name: "ShareTestimonial",
    pattern: `/dashboard/share/testimonial/[testimonialId]`,
    type: "private",
    redirect: false,
    mobileNav: true,
    commonPath: "/share/testimonial",
  },
  {
    name: "Calls",
    pattern: `/dashboard/calls`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/calls/video/upcoming`,
    mobileNav: true,
  },
  {
    name: "CallsUpcoming",
    pattern: `/dashboard/calls/upcoming`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/calls/video/upcoming`,
    mobileNav: true,
  },
  {
    name: "CallsCompleted",
    pattern: `/dashboard/calls/completed`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/calls/video/completed`,
    mobileNav: true,
  },
  {
    name: "NeedAction Video Calls",
    pattern: `/dashboard/calls/video/need-action`,
    type: "private",
    redirect: true,
    mobileNav: true,
  },
  {
    name: "Upcoming Video Calls",
    pattern: `/dashboard/calls/video/upcoming`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Completed Video Calls",
    pattern: `/dashboard/calls/video/completed`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "VideoCalls",
    pattern: `/dashboard/calls/video`,
    type: "private",
    redirect: `/dashboard/calls/video/upcoming`,
    mobileNav: true,
  },
  {
    name: "PackageCalls",
    pattern: `/dashboard/calls/package`,
    type: "private",
    redirect: `/dashboard/calls/package/upcoming`,
    mobileNav: true,
  },
  {
    name: "UpcomingPackageCalls",
    pattern: `/dashboard/calls/package/upcoming`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "CompletedPackageCalls",
    pattern: `/dashboard/calls/package/completed`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "UpcomingWebinarCalls",
    pattern: `/dashboard/calls/webinar/upcoming`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "CompletedWebinarCalls",
    pattern: `/dashboard/calls/webinar/completed`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "WebinarCalls",
    pattern: `/dashboard/calls/webinar`,
    type: "private",
    redirect: `/dashboard/calls/webinar/upcoming`,
    mobileNav: true,
  },
  {
    name: "NeedAction Webinar Calls",
    pattern: `/dashboard/calls/webinar/need-action`,
    type: "private",
    redirect: true,
    mobileNav: true,
  },
  {
    name: "ProductBookings",
    pattern: `/dashboard/calls/product`,
    type: "private",
    redirect: `/dashboard/calls/product`,
    mobileNav: true,
  },
  {
    name: "Queries",
    pattern: `/dashboard/queries`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/queries/pending`,
    mobileNav: true,
  },
  {
    name: "PendingQueries",
    pattern: `/dashboard/queries/pending`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "AnsweredQueries",
    pattern: `/dashboard/queries/answered`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Analytics",
    pattern: `/dashboard/analytics`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/home`,
    mobileNav: true,
  },
  {
    name: "TemplateRating",
    pattern: "/rating-template",
    type: "public",
    redirect: false,
    mobileNav: true,
    exact: true,
  },
  {
    name: "FeaturedPcg",
    pattern: `/featured/[page]`,
    type: "public",
    redirect: false,
    mobileNav: true,
    exact: true,
  },
  {
    name: "Referral",
    pattern: `/dashboard/referral`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Rewards",
    pattern: `/dashboard/rewards`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Profile",
    pattern: `/dashboard/profile`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/profile/details`,
  },
  {
    name: "ProfileDetails",
    pattern: `/dashboard/profile/details`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ProfileSettings",
    pattern: `/dashboard/profile/settings`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ProfileAccount",
    pattern: `/dashboard/profile/account`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ProfileCheckout",
    pattern: `/dashboard/profile/checkout`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "BuyDocumentSuccess",
    pattern: "/buydoc-success/[serviceId]",
    type: "public",
    redirect: false,
    mobileNav: true,
    exact: true,
    commonPath: "/buydoc-success",
  },
  {
    name: "Beacons",
    pattern: "/beacons/[username]",
    type: "public",
    redirect: false,
    mobileNav: false,
    exact: true,
  },
  {
    name: "TestimonialSuccess",
    pattern: "/testimonial-confirmation",
    type: "public",
    redirect: false,
    mobileNav: true,
    exact: true,
  },
  {
    name: "VideoCallInterceptor",
    pattern: "/videocallv2/[videoCallInfoId]",
    type: "public",
    redirect: false,
    mobileNav: false,
    exact: true,
    commonPath: "/videocallv2",
  },
  {
    name: "VideoCallEnded",
    pattern: "/booking/[bookingId]/videocall-ended",
    type: "public",
    redirect: false,
    mobileNav: true,
    exact: true,
  },
  {
    name: "Availability",
    pattern: `/dashboard/availability`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/availability/configurations`,
    mobileNav: true,
  },
  {
    name: "Schedule",
    pattern: `/dashboard/availability/schedule`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Leaderboard",
    pattern: `/dashboard/leaderboard`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Configurations",
    pattern: `/dashboard/availability/configurations`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Marketing",
    pattern: `/dashboard/marketing`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/marketing/email`,
    mobileNav: true,
  },
  {
    name: "EmailMarketing",
    pattern: `/dashboard/marketing/email`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "WhatsappMarketing",
    pattern: `/dashboard/marketing/whatsapp`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Funnels",
    pattern: `/dashboard/marketing/funnels`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Payments",
    pattern: `/dashboard/payments`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "EditPayments",
    pattern: `/dashboard/payments/edit`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "Services",
    pattern: `/dashboard/services`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/services/video`,
    mobileNav: true,
  },
  {
    name: "VideoServices",
    pattern: `/dashboard/services/video`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "SubscriptionServices",
    pattern: `/dashboard/services/subscriptions`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "QueryServices",
    pattern: `/dashboard/services/text`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "WebinarServices",
    pattern: `/dashboard/services/webinar`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "DocumentServices",
    pattern: `/dashboard/services/document`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "PackageServices",
    pattern: `/dashboard/services/package`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "AddNewServices",
    pattern: `/dashboard/services/add`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "AddServiceDetails",
    pattern: `/dashboard/services/add-details`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/services/add-details/basic-details`,
    mobileNav: false,
  },
  {
    name: "AddServiceBasicDetails",
    pattern: `/dashboard/services/add-details/basic-details`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "AddServiceSchedulingDetails",
    pattern: `/dashboard/services/add-details/scheduling`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "ServiceEdit",
    pattern: `/dashboard/services/edit`,
    type: "private",
    redirect: true,
    redirectTo: `/dashboard/services/edit/basic-details`,
    mobileNav: false,
  },
  {
    name: "ServiceEditBasic",
    pattern: `/dashboard/services/edit/basic-details`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "ServiceEditScheduling",
    pattern: `/dashboard/services/edit/scheduling`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "ServiceEditShare",
    pattern: `/dashboard/services/edit/share`,
    type: "private",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "UseCaseLandingPage",
    pattern: `/for/[category]`,
    type: "public",
    redirect: false,
    mobileNav: true,
    commonPath: "/for",
  },
  {
    name: "Feedback",
    pattern: `/dashboard/feedback`,
    type: "private",
    redirect: false,
    mobileNav: true,
  },
  {
    name: "ReschedulePage",
    pattern: `/reschedule-call/[bookingId]`,
    type: "public",
    redirect: false,
    mobileNav: false,
    commonPath: "/reschedule-call",
  },
  {
    name: "SignupCompliance",
    pattern: `/compliancecalendar/signup`,
    type: "public",
    redirect: true,
    redirectTo: `/signup/compliancecalendar`,
    mobileNav: false,
  },
  {
    name: "EditMarketingTemplate",
    pattern: `/dashboard/marketing/template/edit/[id]`,
    type: "private",
    redirect: false,
    mobileNav: false,
    commonPath: "/dashboard/marketing/template/edit",
  },
  {
    name: "EditFunnelChannels",
    pattern: `/dashboard/marketing/funnel/[id]/channels`,
    type: "private",
    redirect: false,
    mobileNav: false,
    commonPath: "/dashboard/marketing/funnel",
  },
  {
    name: "EditFunnelDetails",
    pattern: `/dashboard/marketing/funnel/[id]/details`,
    type: "private",
    redirect: false,
    mobileNav: false,
    commonPath: "/dashboard/marketing/funnel",
  },
  {
    name: "Space",
    pattern: `/dashboard/space`,
    type: "public",
    redirect: false,
    mobileNav: false,
    commonPath: "/dashboard/space",
  },
  {
    name: "Avatar",
    pattern: `/dashboard/avatar`,
    type: "public",
    redirect: false,
    mobileNav: false,
    commonPath: "/dashboard/avatar",
  },
  {
    name: "Youwecan",
    pattern: `/change-the-world`,
    type: "public",
    redirect: false,
    mobileNav: false,
    commonPath: "/change-the-world",
  },
  {
    name: "Twitter Login",
    pattern: `/twitterlogin`,
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "AISearch",
    pattern: "/ai-search",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
  {
    name: "Topbot",
    pattern: "/topbot",
    type: "public",
    redirect: false,
    mobileNav: false,
  },
];

export const getCurrentPage = (router): string => {
  const match = Routes.find((route) => route.pattern === router?.pathname);
  const currentPage = match?.name ? insertSpaces(match?.name) : null;
  return currentPage || "NA";
};

export const getCurrentRouteConstant = (router) => {
  const match = Routes.find((route) => route.pattern === router?.pathname);
  return match || {};
};
